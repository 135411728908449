#ag-canvas {
  height: 100%;
  padding: 20px;
}

.ag-item :first-child {
  border-radius: 6px;
}

.vipsSide {
    -webkit-mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
}

.scrollContainer {
    -webkit-mask-image: linear-gradient(to top, black 80%, transparent 100%);
    mask-image: linear-gradient(to top, black 80%, transparent 100%);
}

.agoraButtonAutoAudio {
    position: absolute;
    z-index: 1005;
    top: 30px;
    left: 30px;
}

.ag-item {
  position: relative;
  border-radius: 6px;
  /* border: 2px #00B6ED solid; */
  /* background: url('../assets/images/avatar.png') center no-repeat; "https://e-cdns-images.dzcdn.net/images/artist/9e40dcd4b0f0cfc6b7a23b9a6f2f9077/312x312-000000-80-0-0.jpg" */
  height: 100%;
  width: 100%;
}

.ag-name {
    position: absolute;
    white-space: nowrap;
    font-size: 14px;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    color: white;
    z-index: 50;
    padding: 6px 15px;
    border-radius: 0 0 10px 0;
    display: none;
}

.coHostNameTag {
    bottom: 0;
    top: auto;
    text-overflow: ellipsis;
    width: 100%;
    max-width: fit-content;
    overflow: hidden;
    border-radius: 0 10px 0 0;
    display: none;
}

.vipNameTag {
    bottom: 0;
    top: auto;
    text-overflow: ellipsis;
    width: 100%;
    max-width: fit-content;
    overflow: hidden;
    border-radius: 0 10px 0 0;
    display: none;
}

/* .ag-name-vip {
    position: absolute;
    font-size: 9px;
    bottom: 10px;
    left: 0px;
    background-color: rgba(0,0,0,0.5);;
    color: white;
    z-index: 100;
    padding: 2px 10px;
    border-top-left-radius:0px !important;
    border-top-right-radius:10px !important;
    border-bottom-left-radius:0px !important;
    border-bottom-right-radius:10px !important;
} */

#ag-resize-container {
  background-image: none!important;
  background-color: black;
  display: flex;
  justify-content: center;
  height: center;
}

#ag-resize-container .ag-item {
  border: none!important;
}


/* button group */

.ag-btn-group {
    /*
  height: 70px;
  width: calc(100% - 60px);
  */
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  background: transparent;
  z-index: 11;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ag-btn-group:hover,
.ag-btn-group.active {
  background: rgba(18, 74, 99, .8);
}

.ag-btn {
  z-index: 12;
  opacity: 0;
  color: white;
  /* width: 35px;
    height: 35px; */
  cursor: pointer;
  font-size: 60px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ag-btn:hover {
  color: #00B6ED;
}

.ag-btn.disabled,
.ag-btn.disabled:hover {
  color: #637C8E;
  cursor: not-allowed;
}

.ag-btn-group:hover .ag-btn,
.ag-btn-group.active .ag-btn {
  opacity: 1;
}

.ag-btn.off.videoControlBtn .ag-icon-camera {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera-off {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera {
  display: inline-block;
}

.ag-btn.off.videoControlBtn .ag-icon-camera-off {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic-off {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic-off {
  display: inline-block;
}

.insiderAvatar {
    background-position: top center !important;
}

.hostAvatar {
    overflow: hidden;
}

.hostAvatar video {
    border-radius: 36px;
}

.heightFiftyPercent video {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.cohostAvatar {
    height: 100%;
    width: 100%;
}

#cohosts {
    justify-content: space-around;
}

#cohostsContainerId video {
    border-bottom-left-radius: 36px;
    border-bottom-right-radius: 36px;
}

.vipAvatar {
    width: 48px;
    height: 48px;
    margin-bottom: 10px;
    overflow: hidden;
    border-radius: 100px;
    border: 2px solid #C92D7A;
    box-sizing: border-box;
}

/* .vipAvatar::before {
    float: left;
    padding-top: 100%;
    content: "";
} */

.insiderAvatar video {
    overflow: hidden;
}

#muteMyVideo.videoOn:after {
    content:url('/src/assets/icons/video-solid.svg');
    height: 12px;
    width: 12px;
    display: inline-block;
    margin-left: 12px;
}

#muteMyVideo.videoOff:after {
    content:url('/src/assets/icons/video-solid.4989779a-slash.svg');
    height: 12px;
    width: 12px;
    display: inline-block;
    margin-left: 12px;
}

#muteMyAudio.audioOn::after {
    content:url('/src/assets/icons/audio-solid.4989779a.svg');
    height: 12px;
    width: 12px;
    display: inline-block;
    margin-left: 12px;
}

#muteMyAudio.audioOff::after {
    content:url('/src/assets/icons/audio-solid.4989779a-slash.svg');
    height: 12px;
    width: 12px;
    display: inline-block;
    margin-left: 12px;
}

.portraitHostView {
    /* padding-bottom: 90% !important; */
    /* max-width: 500px !important; */
}

.landscapeHostView {
    /* padding-bottom: 60%; */
    /* max-width: 1000px; */
}

@media only screen and (max-width: 1200px) {
    .ag-name {
        font-size: 12px;
    }

    .vipAvatar {
    }
}

@media only screen and (max-width: 992px) {
    .ag-name {
        font-size: 12px;
    }
}

@media only screen and (max-width: 812px) {
    .ag-name {
        font-size: 10px;
    }

    .vipAvatar {
    }

    .insiderAvatar video {
        overflow: hidden;
    }
}

@media only screen and (max-width: 576px) {
    .ag-name {
        font-size: 10px;
        padding: 3px 10px;
    }

    .hostAvatar {
        border-radius: 0;
    }

    .hostAvatar video {
        border-radius: 0;
    }

    #cohostsContainerId video {
        border-radius: 0;
    }

    .vipAvatar video {
        border-radius: 100px;
    }
}

@media only screen and (max-width: 320px) {
    .vipAvatar {
    }

    .hostAvatar {
        border-radius: 0;
    }
}
