.noScrollBody {
    overflow: hidden;
}
.soin {
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
    position: absolute;
    left: 300;
    top: 100;
    margin-left: 425px;
    cursor: pointer;
}

.soin .itemInfoContainer {
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
}

.itemInfoContainer {
    transform: perspective(800px) rotateY(45deg) rotateX(5deg) rotateZ(-5deg);
}

/* LEFT */
.posFront {
    filter: blur(0px);
    z-index: 100;
}
.posMiddle {
    filter: blur(5px);
    transform: scale(0.5) translate(150%, 0);
    z-index: 99;
}
.posBack {
    filter: blur(25px);
    transform: scale(0.25) translate(180%, 0);
    z-index: 98;
}
.posHidden {
    filter: blur(26px);
    opacity: 0;
    transform: scale(2) translate(-180%, 0);
    z-index: 97;
}
.posHidden2 {
    filter: blur(26px);
    opacity: 0;
    transform: scale(0.25) translate(180%, 0);
    z-index: 96;
}

/* RIGHT */
.posFrontRight {
    filter: blur(0px);
    z-index: 100;
}

.posMiddleRight {
    filter: blur(5px);
    transform: scale(0.5) translate(-140%, 0);
    z-index: 99;
}
.posMiddleRight .itemInfoContainer {
    opacity: 0;
    height: 75px;
}

.posBackRight {
    filter: blur(25px);
    transform: scale(0.25) translate(-180%, 0);
    z-index: 98;
}
.posBackRight .itemInfoContainer {
    opacity: 0;
    height: 75px;
}

.posHiddenRight {
    filter: blur(26px);
    opacity: 0;
    transform: scale(2) translate(180%, 0);
    z-index: 97;
}
.posHiddenRight .itemInfoContainer {
    opacity: 0;
    height: 75px;
}

.posHidden2Right {
    filter: blur(26px);
    opacity: 0;
    transform: scale(0.25) translate(-180%, 0);
    z-index: 96;
}
.posHidden2Right .itemInfoContainer {
    opacity: 0;
    height: 75px;
}
